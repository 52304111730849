import React, { useState } from "react";
import * as styles from "./RichTextImage.module.css";
import { KontentMediaImage, PageLink } from "../../../types/index";
import { formatImages } from "../../../lib/images";
import { GatsbyImage, withArtDirection } from "gatsby-plugin-image";
import { KontentImageElement } from "../KontentImageElement/KontentImageElement";
import { getMultiChoice } from "../../../lib/multichoice";
import { CustomRichTextElement } from "../CustomRichTextElement/CustomRichTextElement";
import { Link } from "../Link/Link";
import Modal from "react-modal";
import CloseButton from "../CloseButton/CloseButton";

interface Props extends KontentMediaImage {
  internal: { type: String };
  system?: { id: String; codename: String } | undefined;
  link?: PageLink;
}

export const RichTextImage: React.FC<Props> = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const images = formatImages(props.elements);
  const caption = props.elements?.caption;
  const alignment = getMultiChoice(props.elements?.image_alignment) || "center";
  const overlay = getMultiChoice(props.elements?.overlay) === "yes";

  let classes = "";
  if (alignment === "center" || !alignment) {
    classes += styles.center;
  }
  if (alignment === "left") {
    classes += `${styles.left} ${styles.aligned}`;
  }
  if (alignment === "right") {
    classes += `${styles.right} ${styles.aligned}`;
  }

  const handleImageClick = (e: React.SyntheticEvent) => {
    setModalOpen(true);
  };

  if (overlay) {
    classes += ` ${styles.overlay}`;
  }

  const renderImage = () => {
    if (
      images?.smallImage?.item?.gatsbyImageData?.images?.fallback &&
      images?.largeImage?.item?.gatsbyImageData?.images?.fallback
    ) {
      let gatsbyImages = withArtDirection(
        images?.largeImage?.item?.gatsbyImageData,
        [
          {
            media: "(max-width: 576px)",
            image: images.smallImage.item.gatsbyImageData,
          },
        ]
      );

      return (
        <GatsbyImage
          image={gatsbyImages}
          alt={images?.largeImage?.alt}
          objectFit="cover"
        />
      );
    }

    const imageWidth = images.largeImage?.item?.width;
    const imageHeight = images.largeImage?.item?.height;
    const imageSize = images.largeImage?.item?.size;

    return (
      <KontentImageElement
        image={images?.largeImage?.item}
        alt={images?.largeImage.alt}
        width={imageWidth < 1500 ? imageWidth : 1500}
        height={
          imageWidth < 1500 ? imageHeight : (1500 * imageHeight) / imageWidth
        }
        options={{
          quality: imageSize && imageSize > 8000000 ? 25 : 75,
        }}
        layout="constrained"
      />
    );
  };

  const renderImageWithWrapper = () => {
    if (props.link?.href) {
      return (
        <Link to={props.link.href} target={props.link.target}>
          {renderImage()}
        </Link>
      );
    }

    if (overlay) {
      return (
        <button onClick={handleImageClick} aria-label="View image in overlay">
          {renderImage()}
        </button>
      );
    }

    return renderImage();
  };

  return (
    <div className={`${styles.outerContainer} ${classes}`}>
      <div className={styles.innerContainer}>
        {renderImageWithWrapper()}
        {caption && caption?.value !== "<p><br></p>" && (
          <CustomRichTextElement content={caption} />
        )}
        {overlay && (
          <Modal
            isOpen={modalOpen}
            preventScroll={true}
            appElement={
              typeof document === "undefined" ? undefined : document?.body
            }
            shouldCloseOnEsc={true}
            onRequestClose={() => setModalOpen(false)}
            style={{
              overlay: {
                zIndex: 1000,
              },
              content: {
                inset: "50% auto auto 50%",
                width: "auto",
                border: "none",
                borderRadius: 0,
                padding: "2rem",
                background: "transparent",
                textAlign: "center",
                transform: "translate(-50%, -50%)",
              },
            }}
          >
            <div className={styles.modalCloseButton}>
              <CloseButton
                visualStyle="dark"
                onClick={() => setModalOpen(false)}
                size={24}
              />
            </div>
            {renderImage()}
            {caption && caption?.value !== "<p><br></p>" && (
              <div className={styles.caption}>
                <CustomRichTextElement content={caption} />
              </div>
            )}
          </Modal>
        )}
      </div>
    </div>
  );
};
