import React from "react";
import * as styles from "./FormattedRichText.module.css";
import {
  KontentRichText,
  ComponentProps,
  ComponentWidth,
} from "../../../types/index";
import { CustomRichTextElement } from "../../elements/CustomRichTextElement/CustomRichTextElement";

interface Props extends ComponentWidth, ComponentProps {
  content: KontentRichText;
  fontColor: string | undefined;
  bgColor: string | undefined;
  fontFamily: string | undefined;
  fontSize: string | undefined;
  headingStyle: string | undefined;
  margin: {
    top: string;
    bottom: string;
    left: string;
    right: string;
  };
  padding: {
    top: string;
    bottom: string;
    left: string;
    right: string;
  };
}

export const FormattedRichText: React.FC<Props> = ({
  content,
  fontColor,
  bgColor,
  fontFamily,
  fontSize,
  margin,
  padding,
  anchor,
  hideOnMobile,
  width,
  headingStyle,
}) => {
  let classes = `${styles.formattedRichText} `;

  const removeContainedPadding =
    padding.left === "default" && width === "contained";

  console.log("removeContainedPadding", removeContainedPadding);
  const style: React.CSSProperties = {
    color: fontColor,
    backgroundColor: bgColor,
    paddingLeft: removeContainedPadding ? 0 : padding.left,
  };

  if (fontFamily) {
    classes += `font-${fontFamily} `;
  }

  if (fontSize) {
    classes += `font-size-${fontSize} `;
  }

  if (margin?.top) {
    classes += `margin-top--${margin.top} `;
  }

  if (margin?.bottom) {
    classes += `margin-bottom--${margin.bottom} `;
  }

  if (margin?.left) {
    classes += `margin-left--${margin.left} `;
  }

  if (margin?.right) {
    classes += `margin-right--${margin.right} `;
  }

  if (padding?.top) {
    classes += `padding-top--${padding.top} `;
  }

  if (padding?.bottom) {
    classes += `padding-bottom--${padding.bottom} `;
  }

  if (padding?.left) {
    classes += `padding-left--${padding.left} `;
  }

  if (padding?.right) {
    classes += `padding-right--${padding.right} `;
  }

  if (hideOnMobile) {
    classes += `hide-mobile `;
  }

  if (width === "contained") {
    classes += `contained-component `;
  }

  if (headingStyle) {
    classes += `${headingStyle} `;
  }

  return (
    <CustomRichTextElement
      anchor={anchor}
      content={content}
      style={style}
      classes={classes}
    />
  );
};
