import React from "react";
import { graphql } from "gatsby";
import { FormattedRichText } from "./FormattedRichText";
import {
  KontentRichText,
  KontentBrandColor,
  KontentMultiChoice,
  MarginPaddingSizes,
  StringValue,
} from "../../../types/index";
import { getColor } from "../../../lib/color";
import { getMultiChoice } from "../../../lib/multichoice";
import {
  ComponentProps,
  KontentComponentLayout,
  KontentComponentSettings,
} from "../../../types/index";

interface Props extends KontentComponentSettings, KontentComponentLayout {
  background_color: {
    value: KontentBrandColor[];
  };
  font_color: {
    value: KontentBrandColor[];
  };
  font_family: KontentMultiChoice<
    "avenir_book" | "avenir_medium" | "avenir_black"
  >;
  font_size: KontentMultiChoice<
    "normal" | "x_small" | "small" | "medium" | "large" | "x_large" | "xx_large"
  >;
  text_content: KontentRichText;
  vertical_margins__margin_bottom: KontentMultiChoice<MarginPaddingSizes>;
  vertical_margins__margin_top: KontentMultiChoice<MarginPaddingSizes>;
  vertical_padding__padding_bottom: KontentMultiChoice<MarginPaddingSizes>;
  vertical_padding__padding_top: KontentMultiChoice<MarginPaddingSizes>;
  horizontal_margins__margin_left: KontentMultiChoice<MarginPaddingSizes>;
  horizontal_margins__margin_right: KontentMultiChoice<MarginPaddingSizes>;
  horizontal_padding__padding_left: KontentMultiChoice<MarginPaddingSizes>;
  horizontal_padding__padding_right: KontentMultiChoice<MarginPaddingSizes>;
  heading_style: KontentMultiChoice<"h1" | "h2" | "h3" | "h4" | "h5" | "h6">;
}

const FormattedRichTextContainer: React.FC<Props> = (props) => {
  const fontColor = getColor(props?.font_color);
  const bgColor = getColor(props?.background_color);
  const fontFamily = getMultiChoice(props?.font_family);
  const fontSize = getMultiChoice(props?.font_size);
  const margin = {
    top: getMultiChoice(props.vertical_margins__margin_top),
    bottom: getMultiChoice(props.vertical_margins__margin_bottom),
    left: getMultiChoice(props.horizontal_margins__margin_left),
    right: getMultiChoice(props.horizontal_margins__margin_right),
  };
  const padding = {
    top: getMultiChoice(props.vertical_padding__padding_top),
    bottom: getMultiChoice(props.vertical_padding__padding_bottom),
    left: getMultiChoice(props.horizontal_padding__padding_left),
    right: getMultiChoice(props.horizontal_padding__padding_right),
  };
  const headingStyle = getMultiChoice(props.heading_style);

  return (
    <FormattedRichText
      id={props.id}
      content={props.text_content}
      fontColor={fontColor}
      bgColor={bgColor}
      fontFamily={fontFamily}
      fontSize={fontSize}
      margin={margin}
      padding={padding}
      headingStyle={headingStyle}
      anchor={props.component_settings__anchor_name?.value}
      hideOnMobile={
        getMultiChoice(props.component_settings__hide_on_mobile) === "yes"
      }
      width={getMultiChoice(props.component_layout__component_width)}
    />
  );
};

export default FormattedRichTextContainer;

export const fragmentFormattedRichTextContainer = graphql`
  fragment FormattedRichText on kontent_item_component___formatted_rich_text {
    system {
      id
      codename
    }
    internal {
      type
    }
    elements {
      text_content {
        ...kontentSimpleRichText
      }
      font_size {
        value {
          codename
        }
      }
      font_family {
        value {
          codename
        }
      }
      font_color {
        ...brandColor
      }
      heading_style {
        value {
          codename
        }
      }
      background_color {
        ...brandColor
      }
      horizontal_margins__margin_left {
        value {
          codename
        }
      }
      horizontal_margins__margin_right {
        value {
          codename
        }
      }
      horizontal_padding__padding_left {
        value {
          codename
        }
      }
      horizontal_padding__padding_right {
        value {
          codename
        }
      }
      vertical_margins__margin_bottom {
        value {
          codename
        }
      }
      vertical_margins__margin_top {
        value {
          codename
        }
      }
      vertical_padding__padding_bottom {
        value {
          codename
        }
      }
      vertical_padding__padding_top {
        value {
          codename
        }
      }
      component_settings__anchor_name {
        value
      }
      component_settings__hide_on_mobile {
        value {
          codename
        }
      }
      component_layout__component_width {
        value {
          codename
        }
      }
    }
  }
`;
